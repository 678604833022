.navbar {
	padding: 1rem 1.5rem;
	border-bottom: 1px solid theme('borderColor.DEFAULT');
	color: theme('colors.gray.600');
	text-transform: uppercase;

	a {
		color: currentColor;

		&:visited {
			color: currentColor;
		}
	}
}

.pulse {
	animation: pulse 0.3s ease-in-out;
}

@keyframes pulse {
	0%,
	100% {
		transform: scale(1);
	}

	50% {
		transform: scale(1.1);
		background-color: theme('colors.primary.200');
	}
}
