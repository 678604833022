.embla {
	overflow: hidden;
	position: relative;
}

.embla__container {
	width: 100%;
	display: flex;

	:global .single-view {
		flex: 0 0 100%;
	}
}

.embla__slide {
	margin: 0;
	flex: 0 0 100%;

	@media (min-width: theme('screens.sm')) {
		padding: 0.25rem;
		flex: 0 0 50%;
	}

	@media (min-width: theme('screens.lg')) {
		padding: 0.25rem;
		flex: 0 0 33.3333%;
	}

	@media (min-width: theme('screens.xl')) {
		padding: 0.25rem;
		flex: 0 0 25%;
	}
}

.nextButton,
.previousButton {
	position: absolute;
	background-color: theme('colors.gray.50'/50%);
	color: theme('colors.primary.DEFAULT');
	border-radius: 100%;
	line-height: 1;
	font-size: 1rem;
	font-family: Arial, Helvetica, sans-serif;
	padding: 0.5rem;
	top: 50%;
	transform: translateY(-150%);
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: 1px 1px 5px 1px rgb(0, 0, 0, 0.15);
}

.nextButton {
	right: 1rem;
	padding-right: 0.25rem;
}

.previousButton {
	left: 1rem;
	padding-left: 0.25rem;
}
