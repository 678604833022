.main {
	display: flex;
	flex-direction: column;
	user-select: none;
	width: 100%;
}

.thumbnailWrapper {
	position: relative;
	overflow: hidden;
	border-radius: theme('borderRadius.DEFAULT');
	border: 1px solid theme('borderColor.DEFAULT');
}

.thumbnail {
	display: block;
	aspect-ratio: 1/1;
	color: currentColor;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.details {
	padding: 0.25rem 0.25rem;
	font-size: 0.85rem;

	a {
		text-decoration: none;
		color: theme('colors.muted');
	}
}

.nameAndPrice {
	display: flex;
	margin-top: 0.25rem;
	justify-content: space-between;
	align-items: flex-start;
	font-size: 0.75rem;
	gap: 0 1rem;
}

.unitPrice {
	font-weight: 500;
	white-space: nowrap;
}
