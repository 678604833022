.footer {
	background-color: theme('colors.gray.100');
	border-top: 2px solid theme('colors.primary.DEFAULT');

	h2 {
		font-size: 1.1rem;
		text-transform: uppercase;
		letter-spacing: 0;
		margin: 0;
	}

	li {
		margin: 0.95rem 0;

		& > a,
		& > a:visited {
			color: theme('colors.black');
		}
	}

	details {
		margin: 0 0 0.7rem;

		@media (min-width: theme('screens.lg')) {
			flex: 0 0 33.333%;
		}

		summary {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			padding-bottom: 0.3rem;
			margin-bottom: 1.2rem;
			list-style: none;
			cursor: pointer;
			border-bottom: 1px solid theme('colors.gray.300');

			&,
			& > h2 {
				color: theme('colors.primary.DEFAULT');
			}

			&::-webkit-details-marker {
				display: none;
			}

			@media (min-width: theme('screens.lg')) {
				border-bottom: 0;

				&,
				& > h2 {
					color: currentColor;
				}
			}
		}

		.arrow {
			transition: all 0.2s ease-in-out;
			width: 1em;
			height: 1em;

			@media (min-width: theme('screens.lg')) {
				display: none;
			}
		}

		&[open] {
			.arrow {
				transform: rotate(180deg);
				transform-origin: center;
			}
		}
	}
}

.wrapper {
	@apply container;
	padding: 0;
	display: flex;
	flex-direction: column;
	width: 100%;

	@media (min-width: theme('screens.md')) {
		flex-direction: row;
	}

	@media (min-width: theme('screens.lg')) {
		padding-top: 1.5rem;
		padding-bottom: 1rem;
	}
}

.left {
	flex: 1 0 auto;
	display: flex;
	flex-direction: column;

	@media (min-width: theme('screens.lg')) {
		flex: 0 0 60%;
		flex-direction: row;
		gap: 0 1rem;
	}
}

.right {
	line-height: 1.5;
	margin-top: 1.5rem;
	flex: 0 0 auto;

	@media (min-width: theme('screens.md')) {
		margin-left: 2rem;
		padding-left: 3rem;
		border-left: 1px solid theme('colors.gray.300');
		margin-top: 0;
	}
}

.copyright {
	color: theme('colors.black');
	background-color: theme('colors.white');
	font-size: 0.7rem;
}

.social {
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font: normal normal normal 36px/1 'social';
	display: inline-block;
	margin-inline-end: 0.35rem;
	position: relative;
	line-height: 0;
	top: 0.04em;
}

.facebook::before {
	@extend .social;
	content: 'f';
}

.instagram::before {
	@extend .social;
	content: 'c';
}

.linkedin::before {
	@extend .social;
	content: 'i';
}

.twitter::before {
	@extend .social;
	content: 't';
}

.youtube::before {
	@extend .social;
	content: 'y';
}
