.wrapper {
	gap: 0 0.5rem;
	padding: 0.45rem 0.5rem;
	display: flex;
	align-items: stretch;
	border: 1px solid theme('borderColor.DEFAULT');
	border-radius: theme('borderRadius.DEFAULT');
	position: relative;
	height: 2.15rem;
	font-size: 1rem;
	line-height: 1;

	> * {
		height: 100%;
		width: auto;
	}

	&:focus-within {
		border: 1px solid theme('colors.primary.DEFAULT');
		box-shadow: 0 0 0 3.25px theme('colors.primary.600'/20%);
	}

	&[data-invalid='true'] {
		&:focus-within {
			border: 1px solid theme('colors.danger.DEFAULT');
			box-shadow: 0 0 0 3.25px theme('colors.danger.600'/20%);
		}
	}
}

.wrapper-small {
	height: 28px;
	font-size: 12px;
	padding: 4px 8px 4px;
}

.wrapper-large {
	font-size: 22px;
	height: 40px;
	padding: 4px 8px 4px;
}

.input {
	border: 0;
	width: 100%;
	display: block;
	appearance: none;
	background-color: transparent;
	font-family: Arial, Helvetica, sans-serif;
	font-size: inherit;
	line-height: inherit;

	&:focus,
	&:focus-visible {
		outline: 0;
		border: 0;
		box-shadow: none;
	}
}
